import {
  CustomElementClientMessageType,
  CustomElementHostMessageType,
  ElementValue,
  FileType,
  IAssetDetails,
  IAssetSelectionDialogConfig,
  ICustomElementContext,
  ICustomElementData,
  IDisabledChangedMessageData,
  IElementsChangedMessageData,
  IGetAssetDetailsResponseMessageData,
  IGetItemDetailsResponseMessageData,
  IGetValueResponseMessageData,
  IInitResponseData,
  IItemChangedDetails,
  IItemChangedMessageData,
  IItemExtendedData,
  IItemSelectionDialogConfig,
  ISelectAssetsResponseMessageData,
  ISelectItemsResponseMessageData,
  ISelectedAsset,
  ISelectedItem,
  ISetValueRequestMessageData,
  ISetValueResponseMessageData,
} from '../../client/app/applications/itemEditor/types/CustomElementApi.ts';
import { ICustomElementMessageSender } from './CustomElementMessageSender.ts';
import { getValueRequestData, isStringArray, isUuidArray } from './customElementRuntimeUtils.ts';

export interface ICustomElement {
  readonly init: (
    callback: (element: ICustomElementData, context?: ICustomElementContext) => void,
  ) => void;
  readonly getItemDetails: (itemIds: UuidArray) => Promise<ReadonlyArray<IItemExtendedData> | null>;
  readonly getAssetDetails: (assetIds: UuidArray) => Promise<ReadonlyArray<IAssetDetails> | null>;
  readonly setValue: (value: string | null | ISetValueRequestMessageData) => void;
  readonly onDisabledChanged: (callback: (disabled: boolean) => void) => void;
  readonly selectAssets: (
    config: IAssetSelectionDialogConfig,
  ) => Promise<ReadonlyArray<ISelectedAsset> | null>;
  readonly selectItems: (
    config: IItemSelectionDialogConfig,
  ) => Promise<ReadonlyArray<ISelectedItem> | null>;
  readonly setHeight: (height: number) => void;
  readonly getElementValue: (
    elementCodename: string,
    callback: (value: ElementValue) => void,
  ) => void;
  readonly observeElementChanges: (
    elementCodenames: string[],
    callback: (elementCodenames: string[]) => void,
  ) => void;
  readonly observeItemChanges: (
    callback: (itemChangedDetails: IItemChangedDetails) => void,
  ) => void;
}

// logError fires app insight events, we want to just print an error here
const printError = console.error;

export class CustomElement implements ICustomElement {
  readonly #sender: ICustomElementMessageSender;

  constructor(sender: ICustomElementMessageSender) {
    this.#sender = sender;
  }

  public setValue(value: string | null | ISetValueRequestMessageData): void {
    const requestData = getValueRequestData(value);

    this.#sender.sendMessageWithReply(
      CustomElementClientMessageType.SetValueRequest,
      CustomElementHostMessageType.SetValueResponse,
      (data: ISetValueResponseMessageData) => {
        if (data.error) {
          printError(`CustomElement: ${data.error}`);
        }
      },
      requestData,
    );
  }

  public init(
    callback: (element: ICustomElementData, context: ICustomElementContext) => void,
  ): void {
    this.#sender.sendMessageWithReply(
      CustomElementClientMessageType.InitDataRequest,
      CustomElementHostMessageType.InitDataResponse,
      (data: IInitResponseData) => {
        callback(data.element, data.context);
      },
    );
  }

  public onDisabledChanged(callback: (disabled: boolean) => void): void {
    if (typeof callback !== 'function') {
      throw Error('Specify a callback function.');
    }

    this.#sender.registerListener(
      CustomElementHostMessageType.OnDisabledChanged,
      (data: IDisabledChangedMessageData) => callback(data.disabled),
    );
  }

  public async selectItems(
    config: IItemSelectionDialogConfig,
  ): Promise<ReadonlyArray<ISelectedItem> | null> {
    return new Promise<ReadonlyArray<ISelectedItem> | null>((resolve, reject) => {
      if (!config) {
        reject('Specify a config parameter.');
      }

      if (typeof config.allowMultiple !== 'boolean') {
        reject('Specify the allowMultiple attribute of the config parameter.');
      }

      this.#sender.sendMessageWithReply(
        CustomElementClientMessageType.SelectItemsRequest,
        CustomElementHostMessageType.SelectItemsResponse,
        (data: ISelectItemsResponseMessageData) => {
          if (data.error) {
            printError(`CustomElement: ${data.error}`);
            reject(data.error);
          } else {
            resolve(data.items);
          }
        },
        { config },
      );
    });
  }

  public async selectAssets(
    config: IAssetSelectionDialogConfig,
  ): Promise<ReadonlyArray<ISelectedAsset> | null> {
    return new Promise<ReadonlyArray<ISelectedAsset> | null>((resolve, reject) => {
      if (!config) {
        reject('Specify a config parameter.');
      }

      if (typeof config.allowMultiple !== 'boolean') {
        reject('Specify the allowMultiple attribute of the config parameter.');
      }

      if (config.fileType !== FileType.All && config.fileType !== FileType.Images) {
        reject('Specify the file type attribute ("all" or "images") of the config parameter.');
      }

      this.#sender.sendMessageWithReply(
        CustomElementClientMessageType.SelectAssetsRequest,
        CustomElementHostMessageType.SelectAssetsResponse,
        (data: ISelectAssetsResponseMessageData) => {
          if (data.error) {
            printError(`CustomElement: ${data.error}`);
            reject(data.error);
          } else {
            resolve(data.assets);
          }
        },
        { config },
      );
    });
  }

  public setHeight(height: number): void {
    if (!Number.isInteger(height) || height < 0) {
      throw Error('The specified height must be a positive integer.');
    }

    this.#sender.sendMessage(CustomElementClientMessageType.SetHeightRequest, { height });
  }

  public getElementValue(elementCodename: string, callback: (value: ElementValue) => void): void {
    if (typeof elementCodename !== 'string') {
      throw Error('Specify a valid codename string.');
    }

    this.#sender.sendMessageWithReply(
      CustomElementClientMessageType.GetValueRequest,
      CustomElementHostMessageType.GetValueResponse,
      (data: IGetValueResponseMessageData) => {
        if (data.error) {
          printError(`CustomElement: ${data.error}`);
        } else {
          callback(data.value);
        }
      },
      { codename: elementCodename },
    );
  }

  public async getAssetDetails(assetIds: UuidArray): Promise<ReadonlyArray<IAssetDetails> | null> {
    return new Promise<ReadonlyArray<IAssetDetails> | null>((resolve, reject) => {
      if (!isUuidArray(assetIds)) {
        reject('Specify a valid array of assetIds.');
      }

      this.#sender.sendMessageWithReply(
        CustomElementClientMessageType.GetAssetDetailsRequest,
        CustomElementHostMessageType.GetAssetDetailsResponse,
        (data: IGetAssetDetailsResponseMessageData) => {
          if (data.error) {
            printError(`CustomElement: ${data.error}`);
            reject(data.error);
          } else {
            resolve(data.details);
          }
        },
        { assetIds },
      );
    });
  }

  public async getItemDetails(
    itemIds: UuidArray,
  ): Promise<ReadonlyArray<IItemExtendedData> | null> {
    return new Promise<ReadonlyArray<IItemExtendedData> | null>((resolve, reject) => {
      if (!isUuidArray(itemIds)) {
        reject('Specify a valid array of itemIds.');
      }

      this.#sender.sendMessageWithReply(
        CustomElementClientMessageType.GetItemDetailsRequest,
        CustomElementHostMessageType.GetItemDetailsResponse,
        (data: IGetItemDetailsResponseMessageData) => {
          if (data.error) {
            printError(`CustomElement: ${data.error}`);
            reject(data.error);
          } else {
            resolve(data.details);
          }
        },
        { itemIds },
      );
    });
  }

  public observeElementChanges(
    elementCodenames: string[],
    callback: (elementCodenames: string[]) => void,
  ): void {
    if (!isStringArray(elementCodenames)) {
      throw Error('Specify a valid list of element codenames as string array.');
    }
    if (typeof callback !== 'function') {
      throw Error('Specify a callback function.');
    }

    this.#sender.sendMessageWithListener(
      CustomElementClientMessageType.ObserveElementChanges,
      CustomElementHostMessageType.OnElementsChanged,
      (data: IElementsChangedMessageData) => callback(data.elements),
      { elements: elementCodenames },
    );
  }

  public observeItemChanges(callback: (itemDetails: IItemChangedDetails) => void): void {
    if (typeof callback !== 'function') {
      throw Error('Specify a callback function.');
    }

    this.#sender.sendMessageWithListener(
      CustomElementClientMessageType.ObserveItemChanges,
      CustomElementHostMessageType.OnItemChanged,
      (data: IItemChangedMessageData) => callback(data.itemChangedDetails),
      {},
    );
  }
}
