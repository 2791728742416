import { createGuid } from '@kontent-ai/utils';
import {
  CustomElementApi,
  CustomElementClientMessageType,
  CustomElementHostMessageType,
} from '../../client/app/applications/itemEditor/types/CustomElementApi.ts';

type Callback<A> = (data?: A) => void;

type CallbackByMessageType<TClientMessageType extends string, TMessageData> = {
  [msgType in TClientMessageType]?: Callback<TMessageData>;
};

type ExtensionMessage<TType, TData> = {
  readonly type: TType;
  readonly data?: TData;
  readonly requestId?: string;
};

interface ICustomExtensionMessageSender<TClientMessageType, THostMessageType, TMessageData> {
  readonly sendMessage: (type: TClientMessageType, data?: TMessageData, requestId?: string) => void;
  readonly registerListener: (type: THostMessageType, callback: Callback<TMessageData>) => void;
  readonly sendMessageWithReply: (
    requestType: TClientMessageType,
    responseType: THostMessageType,
    callback: Callback<TMessageData>,
    data?: TMessageData,
  ) => void;
  readonly sendMessageWithListener: (
    requestType: TClientMessageType,
    responseType: THostMessageType,
    callback: Callback<TMessageData>,
    data?: TMessageData,
  ) => void;
}

class CustomExtensionMessageSender<
  TClientMessageType extends string,
  THostMessageType extends string,
  TMessageData,
> implements ICustomExtensionMessageSender<TClientMessageType, THostMessageType, TMessageData>
{
  private readonly _callbacks: CallbackByMessageType<TClientMessageType, TMessageData> = {};
  private readonly _allowedHostMessageTypes: ReadonlyArray<string>;

  constructor(allowedClientMessageTypes: ReadonlyArray<string>) {
    this._allowedHostMessageTypes = allowedClientMessageTypes;
    window.addEventListener('message', this._processMessage, true);
  }

  public sendMessage(type: TClientMessageType, data?: TMessageData, requestId?: string): void {
    const message: ExtensionMessage<TClientMessageType, TMessageData> = {
      type,
      data,
      requestId,
    };

    if (window.self === window.top) {
      throw Error('Custom element is not hosted in an IFrame');
    }

    window.parent.postMessage(message, '*');
  }

  public sendMessageWithReply(
    requestType: TClientMessageType,
    responseType: THostMessageType,
    callback: Callback<TMessageData>,
    data?: TMessageData,
  ): void {
    if (typeof callback !== 'function') {
      throw Error('Specify a callback function.');
    }

    // Generate unique request id (to allow parallel messages)
    const requestId = createGuid();
    this.registerCallback(responseType, callback, requestId);
    this.sendMessage(requestType, data, requestId);
  }

  public sendMessageWithListener(
    requestType: TClientMessageType,
    responseType: THostMessageType,
    callback: Callback<TMessageData>,
    data?: TMessageData,
  ): void {
    // Generate unique request id (to allow parallel messages)
    const requestId = createGuid();
    this.registerListener(responseType, callback, requestId);
    this.sendMessage(requestType, data, requestId);
  }

  public registerListener(
    type: THostMessageType,
    callback: Callback<TMessageData>,
    requestId?: string,
  ): void {
    const repetitiveCallback = (data: TMessageData) => {
      callback(data);
      this.registerCallback(type, repetitiveCallback, requestId);
    };
    this.registerCallback(type, repetitiveCallback, requestId);
  }

  private registerCallback(
    type: THostMessageType,
    callback: Callback<TMessageData>,
    requestId?: string,
  ): void {
    const callbackKey = this._getCallbackKey(type, requestId);
    this._callbacks[callbackKey] = callback;
  }

  private readonly _executeCallbacks = (
    type: THostMessageType,
    data?: TMessageData,
    requestId?: string,
  ): void => {
    const callbackKey = this._getCallbackKey(type, requestId);
    const callback = this._callbacks[callbackKey];
    this._callbacks[callbackKey] = undefined;
    callback?.(data);
  };

  private readonly _processMessage = (event: MessageEvent): void => {
    if (event.data) {
      const message = event.data as ExtensionMessage<THostMessageType, TMessageData>;

      // Check if the event is known message
      if (this._allowedHostMessageTypes.includes(message.type)) {
        this._executeCallbacks(message.type, message.data as TMessageData, message.requestId);
      }
    }
  };

  private readonly _getCallbackKey = (
    type: THostMessageType,
    requestId?: string,
  ): TClientMessageType => (type + (requestId ? requestId : '')) as TClientMessageType;
}

export interface ICustomElementMessageSender
  extends ICustomExtensionMessageSender<
    CustomElementClientMessageType,
    CustomElementHostMessageType,
    CustomElementApi
  > {}

export class CustomElementMessageSender extends CustomExtensionMessageSender<
  CustomElementClientMessageType,
  CustomElementHostMessageType,
  CustomElementApi
> {}
