import { CustomElementHostMessageType } from '../../client/app/applications/itemEditor/types/CustomElementApi.ts';
import { CustomElement, ICustomElement } from './CustomElement.ts';
import { CustomElementMessageSender } from './CustomElementMessageSender.ts';

const customElement = new CustomElement(
  new CustomElementMessageSender(Object.values(CustomElementHostMessageType)),
);

export const init: ICustomElement['init'] = (callback) => customElement.init(callback);
export const getAssetDetails: ICustomElement['getAssetDetails'] = async (assetIds) =>
  customElement.getAssetDetails(assetIds);
export const getItemDetails: ICustomElement['getItemDetails'] = async (itemIds) =>
  customElement.getItemDetails(itemIds);
export const setValue: ICustomElement['setValue'] = (value) => customElement.setValue(value);
export const onDisabledChanged: ICustomElement['onDisabledChanged'] = (callback) =>
  customElement.onDisabledChanged(callback);
export const selectAssets: ICustomElement['selectAssets'] = async (config) =>
  customElement.selectAssets(config);
export const selectItems: ICustomElement['selectItems'] = async (config) =>
  customElement.selectItems(config);
export const setHeight: ICustomElement['setHeight'] = (height) => customElement.setHeight(height);
export const getElementValue: ICustomElement['getElementValue'] = (elementCodename, callback) =>
  customElement.getElementValue(elementCodename, callback);
export const observeElementChanges: ICustomElement['observeElementChanges'] = (
  elementCodenames,
  callback,
) => customElement.observeElementChanges(elementCodenames, callback);
export const observeItemChanges: ICustomElement['observeItemChanges'] = (callback) =>
  customElement.observeItemChanges(callback);
