import { isUuid } from '../../client/app/_shared/utils/validation/typeValidators.ts';
import { ISetValueRequestMessageData } from '../../client/app/applications/itemEditor/types/CustomElementApi.ts';

export function isStringArray(value: any): value is readonly string[] {
  if (!Array.isArray(value)) {
    return false;
  }
  for (let i = 0; i < value.length; i++) {
    if (typeof value[i] !== 'string') {
      return false;
    }
  }
  return true;
}

export function isUuidArray(value: UuidArray): boolean {
  if (!Array.isArray(value)) {
    return false;
  }
  for (let i = 0; i < value.length; i++) {
    if (!isUuid(value[i])) {
      throw Error(`${value[i]} is not a valid identifier.`);
    }
  }
  return true;
}

const isSetValueRequestMessageData = (input: unknown): input is ISetValueRequestMessageData =>
  !!input &&
  typeof input === 'object' &&
  Object.hasOwn(input, 'value') &&
  Object.hasOwn(input, 'searchableValue');

export function getValueRequestData(
  value: string | null | ISetValueRequestMessageData,
): ISetValueRequestMessageData {
  if (isSetValueRequestMessageData(value)) {
    const { value: valueProp, searchableValue } = value;

    if (typeof valueProp !== 'string' && valueProp !== null) {
      throw Error('The provided value property must be a string or null.');
    }

    if (!!searchableValue && typeof searchableValue !== 'string' && searchableValue !== null) {
      throw Error('The provided searchableValue property must be a string or null.');
    }

    return { value: valueProp, searchableValue };
  }

  if (typeof value !== 'string' && value !== null) {
    throw Error('The provided value must be a string, object or null.');
  }

  return { value, searchableValue: null };
}
